// Here you can add other styles

// logout toastr button style

#logout_confirm button.rrt-button.rrt-ok-btn.toastr-control{
    background-color:rgb(11, 71, 11) !important ;
    font-weight: bolder;
    font-size: large;
    color: white !important;
  }
  #logout_confirm button.rrt-button.rrt-cancel-btn.toastr-control{
    background-color:rgb(92, 5, 5) !important ;
    font-weight: bolder;
    font-size: large;
    color: white !important;
    }
  
    .page-active{background-color: lightblue;}

        //header-dropdown-image
        .c-avatar-img{
          height: 40px;
          width: 40px;
        }
//save button used in forms
.btn-save{
  float: right;
  margin-bottom: 10px;
  border-radius: 50em;
}        
* {
  outline: none;
}